import * as React from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./user.css"

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect } from "react";
import { param } from "jquery";
import { useParams } from "react-router";
const axios = require("axios");


const moduleList = [
  {
    "id": 1,
    "name": "dashboard",
  },
  {
    "id": 2,
    "name": "master",
  },
  {
    "id": 3,
    "name": "SRF",
  },
  {
    "id": 4,
    "name": "Datasheet",
  },
  {
    "id": 5,
    "name": "Certificates",
  },
  {
    "id": 6,
    "name": "Standard In/Out"
  },
  {
    "id": 7,
    "name": "Courier Register"
  },
  {
    "id": 8,
    "name": "Expenses",
  },
  {
    "id": 9,
    "name": "Work Report",
  },
  {
    "id": 10,
    "name": "Summary Report",
  },
  {
    "id": 11,
    "name": "History Card",
  },
  {
    "id": 12,
    "name": "Due Reports",
  },
  {
    "id": 13,
    "name": "Masters Due"
  },
  {
    "id": 14,
    "name": "Enquiry"
  },
  {
    "id": 15,
    "name": "Quotation",
  },
  {
    "id": 16,
    "name": "Invoice",
  },
  {
    "id": 17,
    "name": "Payment",
  },
  {
    "id": 18,
    "name": "Purchase",
  },
  // {
  //   "id": 19,
  //   "name": "Intermediate Procedure",
  // },
  // {
  //   "id": 20,
  //   "name": "Nable Scope"
  // },
  {
    "id": 21,
    "name": "Standard In/Out"
  },
  {
    "id": 22,
    "name": "Enquiry Followup",
  },
  {
    "id": 23,
    "name": "Feedback Report",
  },
  {
    "id": 24,
    "name": "Pickup",
  },
  {
    "id": 25,
    "name": "User",
  }
]


export default function EditUserMaster() {
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modules, setModules] = React.useState(null);
  const [type, setType] = React.useState("");
  const [selectedModules, setSelectedModules] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  const [executing, setExecuting] = React.useState(false);
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0])
  const { id } = useParams()

  const onSubmit = () => {
    setExecuting(true);
    var jsonData = {
      userName: userName,
      password: password,
    };
    if (clientId) {
      jsonData["type"] = 3
    }
    if (selectedModules) {
      jsonData["modules"] = selectedModules.map(m => m.id).join(",")
      jsonData["type"] = 2
    }

    axios
      .patch(BASE_URL + `users/${id}`, jsonData)
      .then((res) => {
        toast("Updated successfully!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };


  const initModules = () => {
    setModules(moduleList)
  }

  function fetchUserDetails() {
    axios.get(BASE_URL + `users/${id}`).then((res) => {
      setUserName(res.data[0].userName)
      setPassword(res.data[0].password)
      if (res.data[0].password) {
        let vals = passVal

      let lowerCaseLetters = /[a-z]/g;
      res.data[0].password.match(lowerCaseLetters) ? vals[0] = 1 : vals[0] = 0

      // Validate capital letters
      let upperCaseLetters = /[A-Z]/g;
      res.data[0].password.match(upperCaseLetters) ? vals[1] = 1 : vals[1] = 0

      // Validate numbers
      let numbers = /[0-9]/g;
      res.data[0].password.match(numbers) ? vals[2] = 1 : vals[2] = 0

      // Validate length
      res.data[0].password.length >= 8 ? vals[3] = 1 : vals[3] = 0
        res.data[0].password.length <= 32 ? vals[4] = 1 : vals[4] = 0
        
        setPasswordVal(vals)
      }
      setType(res.data[0].type)
      res.data[0].modules ?
        setSelectedModules(res.data[0].modules.split(",").map(m => moduleList[m - 1])) :
        setSelectedModules([])
    })
  }

  useEffect(() => {
    initModules()
    fetchUserDetails()
  }, [])


  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit user
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Password *"
            value={password}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters) ? vals[0] = 1 : vals[0] = 0

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters) ? vals[1] = 1 : vals[1] = 0

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? vals[2] = 1 : vals[2] = 0

              // Validate length
              e.target.value.length >= 8 ? vals[3] = 1 : vals[3] = 0
              e.target.value.length <= 32 ? vals[4] = 1 : vals[4] = 0

              setPasswordVal(vals)
              setPassword(e.target.value)
            }}
          />
          <div>
            <p id="letter" class={passVal[0] ? "valid" : "invalid"}>A <b>lowercase</b> letter</p>
            <p id="capital" class={passVal[1] ? "valid" : "invalid"}>A <b>capital (uppercase)</b> letter</p>
            <p id="number" class={passVal[2] ? "valid" : "invalid"}>A <b>number</b></p>
            <p id="lengthMin" class={passVal[3] ? "valid" : "invalid"}>Minimum <b>8 characters</b></p>
            <p id="lengthMax" class={passVal[4] ? "valid" : "invalid"}>Maximum <b>32 characters</b></p>
          </div>
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Client ID (optional)"
            value={}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setClientId(e.target.value)}
          />
        </Grid> */}
        <Grid item xs={9}>
        <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              id="region-select"
              onChange={(e) => {
                setSelectedModules(e.target.value);
              }}
              label="Region"
            >
              <MenuItem value="Ahmedabad">Ahmedabad</MenuItem>
              <MenuItem value="Bangalore">Bangalore</MenuItem>
              <MenuItem value="Chennai">Chennai</MenuItem>
              <MenuItem value="Delhi">Delhi</MenuItem>
              <MenuItem value="Hyderabad">Hyderabad</MenuItem>
              <MenuItem value="Kolkatta">Kolkatta</MenuItem>
              <MenuItem value="Mumbai">Mumbai</MenuItem>
              <MenuItem value="Pune">Pune</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={executing || userName === "" || password === "" || passVal.reduce((s, v) => s + v, 0) < 5}
          onClick={() => {
            // enqueueSnackbar("Standard added successfully !", {
            //     variant: "success",
            // });
            onSubmit();
          }}
        >
          Save user
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
