import React, { useEffect, useState, useRef, useContext } from "react";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import GridInput from "./sections/GridInput";
import DefectiveImages from "./sections/DefectiveImages";
import "./editChecklist.css";
import axios from "axios";
import { BASE_URL, DOMAIN } from "../../global";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router";
import spartanLerrerHead from "./../image/SpartanLetterHead.jpg";
import footerImage from "./../image/footer.png";
import SignatureCanvas from "../../utils/components/SignatureCanvas";
import sampleTemplate from "./sampleTemplate.json";
import QRCode from "react-qr-code";
import moment from "moment/moment";
import { PrintContext } from "./PrintContext";
import MobileGridInput from "./sections/mobileGridInput";
import imageCompression from "browser-image-compression";

export default function MobileEditChecklist(props) {
  const elementRef = useRef(null);
  const navigate = useNavigate();
  const { setIsPrinting, isPrinting } = useContext(PrintContext);
  const { checklistId, checklistTemplateId, isPrint } = useParams();

  const [formConfig, setFormConfig] = useState(sampleTemplate);
  const [templateDetails, setTemplateDetails] = useState({
    name: "checklist template",
  });
  const [checklistDetails, setChecklistDetails] = useState({
    name: null,
    branch: null,
    engineerName: "",
    designation: "",
    contact: "",
    date: null,
    customerName: "",
    siteAddress: "",
    signatureURL: "",
    customerSignatureURL: "",
    sectionsVisible: 0,
    customerNameSignature: "",
  });
  const [clientList, setClientList] = useState([]);
  const [sectionsVisible, setSectionsVisible] = useState(0);
  const userId = localStorage.getItem("id");
  const userType = localStorage.getItem("type");
  const userModulesString = localStorage.getItem("modules");
  const userModules = userModulesString ? userModulesString.split(",") : [];
  const uppercaseUserModules = userModules.map((module) =>
    module.toUpperCase()
  );
  const userState1 = uppercaseUserModules[0];

  checklistDetails.createdBy = userId;
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const branchNames = [
    "AHMEDABAD",
    "BANGALORE",
    "CHENNAI",
    "DELHI",
    "HYDERABAD",
    "KOLKATTA",
    "MUMBAI",
    "PUNE",
  ];
  const filteredBranches =
    Number(userType) === 1
      ? branchNames
      : branchNames.filter((branch) => uppercaseUserModules.includes(branch));

  const filterClientList =
    Number(userType) === 1
      ? clientList
      : clientList.filter(
          (ele) => ele.branch && ele.branch.toUpperCase() === userState1
        );

  const handlePrint = () => {
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
    }
  };

  const NewOptions = function (clientList) {
    return clientList
      .filter((client) => client.companyName !== null)
      .map((client, index) => ({
        id: client.id,
        label: client.companyName + "-" + index + "-" + client.gstNumber,
        // label: client.companyName  + "-" + index +"-"+ client.gstNumber,,
        value: client.companyName + "-" + index + "-" + client.gstNumber,
      }));
  };

  const filterOptions = (options, state) => {
    const inputValue = state?.inputValue?.toLowerCase().trim();

    const filteredOptions = options.filter(
      (option) =>
        option?.label?.toLowerCase().includes(inputValue) ||
        option?.value?.toLowerCase().includes(inputValue)
    );

    return filteredOptions;
  };

  const handleInputConfigTableCellValue = (
    sectionIndex,
    rowIndex,
    cellIndex,
    value
  ) => {
    setFormConfig((prevConfig) => {
      const updatedConfig = [...prevConfig];
      updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex][
        "value"
      ] = value;
      return updatedConfig;
    });
  };
  const handleFileUpload = (sectionIndex, rowIndex, cellIndex, file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    // Check if the file type is allowed
    if (!allowedTypes.includes(file.type)) {
      toast.warn("Only images (JPG,JPEG, PNG, GIF) and PDFs are allowed.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return; // Exit the function if file type is not allowed
    }

    const MAX_FILE_SIZE = 3000000; // 3MB in bytes

    const resizeImage = (image, fileType, callback) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
          // Calculate new dimensions to maintain aspect ratio
          const maxWidth = 800;
          const maxHeight = 600;
          let width = image.width;
          let height = image.height;
  
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
  
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
  
          canvas.width = width;
          canvas.height = height;
  
         ctx.drawImage(image, 0, 0, width, height);
          
          canvas.toBlob(callback, fileType);
    };
  
    const reader = new FileReader();  
    reader.onload = (event) => {
      if (file.type.startsWith('image')) {
        const img = new Image();
        img.src = event.target.result;  
        img.onload = () => {
          if (file.size > MAX_FILE_SIZE || img.width > 800 || img.height > 600) {
            // Compress and resize the image
            resizeImage(img, file.type, (blob) => {
            const compressedFile = new File([blob], file.name, { type: file.type });
  
            // Upload the compressed file
            uploadCompressedFile(sectionIndex, rowIndex, cellIndex, compressedFile);
          });
        } else {
            // File size is acceptable, upload directly
            uploadCompressedFile(sectionIndex, rowIndex, cellIndex, file);
         }
        };
    } else {
      // File is smaller than 1MB, upload as is
      uploadCompressedFile(sectionIndex, rowIndex, cellIndex, file);
    }
    };  
    reader.readAsDataURL(file);
  };
  
  const uploadCompressedFile = (sectionIndex, rowIndex, cellIndex, file) => {
    setFormConfig((prevConfig) => {
      const updatedConfig = [...prevConfig];
      updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex]["fileName"] = file.name;
      return updatedConfig;
    });
  
    const data = new FormData();
    data.append("fileToUpload", file);
    setLoading(true)
    axios.post(
      BASE_URL + `fileUpload/fileUpload.php`,
      data
    )
    .then((res) => {
      if (res.data.includes("[ERROR]")) {
        toast.error("Error: Your image size is too large to upload.( Add less than 3 MB size Image )", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      } else {
        toast("File Uploaded successfully !");
      
        setFormConfig((prevConfig) => {
          const updatedConfig = [...prevConfig];
          updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex]["value"] = res.data;
          updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex]["fileName"] = file.name;
          return updatedConfig;
        });
      }
    })
    .catch((error) => {
      console.error("Error uploading file:", error);
    })
    .finally(() => {
      setLoading(false);
    });
  };



  // const handleFileUpload = async (sectionIndex, rowIndex, cellIndex, file) => {
  //   // Check if the file size exceeds 2.5MB
  //   if (file.size > 2.5 * 1024 * 1024) {
  //     const options = {
  //       maxSizeMB: 2.5,
  //       maxWidthOrHeight: 1920,
  //     };
  
  //     try {
  //       file = await imageCompression(file, options);
  //     } catch (error) {
  //       console.error("Image compression error:", error);
  //       // Handle the error here
  //       return;
  //     }
  //   }
  
  //   const data = new FormData();
  //   data.append("fileToUpload", file);
  
  //   try {
  //     const res = await axios.post(BASE_URL + "fileUpload/fileUpload.php", data);
  
  //     if (res.status === 200) {
  //       toast("File Uploaded successfully!");
  
  //       setFormConfig((prevConfig) => {
  //         const updatedConfig = [...prevConfig];
  //         updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex]["value"] =
  //           res.data;
  //         updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex]["fileName"] =
  //           file.name;
  //         return updatedConfig;
  //       });
  //     }
  //   } catch (error) {
  //     console.error("File upload error:", error);
  //     // Handle the error here
  //   }
  // };
  

  const fetchChecklist = (id) => {
    setLoading(true)
    axios.get(BASE_URL + `checklists/${id}`).then((res) => {
      let data = res.data[0];
      setFormConfig(JSON.parse(data.configValues));
      let checklistDetails = JSON.parse(data.checklistDetails);
      setChecklistDetails(JSON.parse(data.checklistDetails));
      setSectionsVisible(Number(checklistDetails.sectionsVisible));
    })
    .finally(() => {
      setLoading(false);
    });
  };

  // const fetchChecklistTemplate = (id) => {
  //   try {
  //     setLoading(true)
  //     axios.get(BASE_URL + `checklistTemplate/${id}`).then((res) => {
  //       let data = res.data[0];
  //       setFormConfig(JSON.parse(data.config));
  //       let _templateDetails = {
  //         name: data.name,
  //       };
  //       setTemplateDetails({ ...templateDetails, ..._templateDetails });
  //       setChecklistDetails({ ...checklistDetails, name: data.name });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  //   } catch (e) {
  //     console.log("Failed to fetch checklist template: ", e);
  //   }
  // };

  const fetchChecklistTemplate = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(BASE_URL + `checklistTemplate/${id}`);
      const data = res.data[0];
    
      const response = await axios.get(data?.config);
  
      setFormConfig(response.data);
      const _templateDetails = { name: data.name };
      setTemplateDetails((prevTemplateDetails) => ({ ...prevTemplateDetails, ..._templateDetails }));
      setChecklistDetails((prevChecklistDetails) => ({ ...prevChecklistDetails, name: data.name }));
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };
  

  const submitChecklist = () => {
    setChecklistDetails({
      ...checklistDetails,
      date: moment(Date.now()).format("YYYY-MM-DD"),
    });
    let _sectionVisible = sectionsVisible;
    if (_sectionVisible < formConfig.length) {
      _sectionVisible = _sectionVisible + 1;
      setSectionsVisible(_sectionVisible);
    } else {
      if (!window.confirm("Do you want to submit the checklist ?")) {
        return;
      }
    }
    if (checklistId < 1) {
      try {
        let data = {
          checklistDetails: JSON.stringify({
            ...checklistDetails,
            sectionsVisible: _sectionVisible,
          }),
          configValues: JSON.stringify(formConfig),
        };
        if (checklistDetails.customerName) {
          axios.post(BASE_URL + "checklists", data).then((res) => {
            navigate(`/mobileChecklist/edit/${res.data.insertId}/0`);
            toast("checklist created successfully !");
          });
        } else {
          toast.error("Select a customer first!");
        }
      } catch (e) {
        console.log("Failed to create checklist: ", e);
      }
    } else {
      try {
        let data = {
          checklistDetails: JSON.stringify({
            ...checklistDetails,
            sectionVisible: _sectionVisible,
          }),
          configValues: JSON.stringify(formConfig),
        };
        axios
          .patch(BASE_URL + `checklists/${checklistId}`, data)
          .then((res) => {
            toast("template updated successfully !");
            // navigate(`/checklist/edit/${checklistId}/0`);
          });
      } catch (e) {
        console.log("Failed to submit checklist template: ", e);
      }
    }
  };

  const fetchValueFromTable = (tableName) => {
    setLoading(true)
    axios.get(`${BASE_URL}${tableName}`).then((res) => {
      setClientList([...res.data]);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const isSectionValid = (sectionConfig, sectionIndex, formConfig) => {
    const section = formConfig[sectionIndex];

    for (const input of sectionConfig.inputList) {
      for (const cell of input.cells) {
        if (!cell.value || cell.value == "") {
          return false;
        }
      }
    }

    return true;
  };

  useEffect(() => {
    setChecklistDetails({
      ...checklistDetails,
      sectionsVisible: sectionsVisible,
    });
  }, [sectionsVisible]);

  useEffect(() => {
    if (checklistId > 0) {
      fetchChecklist(checklistId);
    } else if (checklistTemplateId > 0) {
      fetchChecklistTemplate(checklistTemplateId);
    }
  }, [checklistId, checklistTemplateId]);

  useEffect(() => {
    fetchValueFromTable("clients");
  }, []);

  const generatePDF = async () => {
    const element = elementRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [1, 1, 1, 1],
        filename: `checklist.pdf`,

        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 10,
          scale: 1,
          letterRendering: true,
          useCORS: true,
        },

        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");
      pdf.setPage(i);
    }

    pdf.save();
  };

  const renderSections = (formConfig) => {
    let sectionRows = [];

    // formConfig.forEach((section, index) =>
    for (let index = 0; index < formConfig.length; index++) {
      if (index > sectionsVisible) break;
      let section = formConfig[index];
      let sectionOutput = null;
      if (section.sectionType === "gridInput") {
        sectionOutput = (
          <MobileGridInput
            id={"section" + index}
            config={section}
            sectionIndex={index}
            handleInputConfigTableCellValue={handleInputConfigTableCellValue}
            handleFileUpload={handleFileUpload}
          />
        );
      } else if (
        section.sectionType === "imageGroup" ||
        section.sectionType === "defectiveImages"
      ) {
        sectionOutput = (
          <Box style={{ border: "1px solid black" }}>
            <DefectiveImages formConfig={formConfig} />
          </Box>
        );
      }

      sectionRows.push(
        <TableRow>
          {section?.sectionName && (
            <h5
              style={{
                textAlign: "left",
                padding: "5px",
                paddingTop: "10px",
                border: "1px solid black",
                pageBreakInside: "avoid",
              }}
            >
              <b>{section?.sectionName}</b>
            </h5>
          )}
          {sectionOutput}
        </TableRow>
      );
    }
    return sectionRows;
  };

  const renderStaticSection = () => {
    return [
      <TableRow>
        <TableCell align="center">
          <h5 style={{ textTransform: "uppercase" }}>
            {checklistDetails.name}
          </h5>
        </TableCell>
      </TableRow>,
      <TableRow>
        <TableCell align="center">
          <Grid container spacing={2}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <Box sx={{ display: "block", displayPrint: "none" }}>
                <span>Date: </span>

                <input
                  type="date"
                  id="birthday"
                  name="birthday"
                  value={
                    checklistDetails.date ||
                    moment(Date.now()).format("YYYY-MM-DD")
                  }
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "5px",
                    float: "right",
                  }}
                  onChange={(e) => {
                    setChecklistDetails({
                      ...checklistDetails,
                      date: e.target.value,
                      //  moment(Date.now()).format("YYYY-MM-DD")
                    });
                  }}
                  disabled={isPrinting}
                />
              </Box>
              <Box sx={{ display: "none", displayPrint: "block" }}>
                Date: {checklistDetails.date}
              </Box>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>,
      <TableRow>
        <TableCell align="center">
          <Box sx={{ display: "block", displayPrint: "none" }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div style={{ textAlign: "left", fontSize: "16px" }}>
                  Customer Name:
                </div>
              </Grid>
              <Grid item style={{ textAlign: "left" }} xs={9}>
                <Autocomplete
                  value={checklistDetails.customerName}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      setChecklistDetails((prevDetails) => ({
                        ...prevDetails,
                        customerName: "",
                        siteAddress: "",
                      }));
                      return;
                    }

                    const selectedClient = filterClientList.find(
                      (client) =>
                        client.id == newValue.id || 
                        // client.companyName === newValue.label?.split("-")[0] ||
                        client.gstNumber === newValue.label?.split("-")[0]
                    );

                    if (selectedClient) {
                      setChecklistDetails((prevDetails) => ({
                        ...prevDetails,
                        customerName: newValue.label?.split("-")[0],
                        siteAddress: selectedClient.address,
                      }));
                    }
                  }}
                  options={NewOptions(filterClientList)}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company Name or Serial Number"
                      variant="outlined"
                      size="small"
                      style={{ maxWidth: "300px" }}
                    />
                  )}
                  disabled={isPrinting}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "none",
              displayPrint: "block",
              textAlign: "left",
              fontSize: "16px",
            }}
          >
            Customer Name: {checklistDetails.customerName}
          </Box>
        </TableCell>
      </TableRow>,
      <TableRow>
        <TableCell align="center">
          <Box sx={{ display: "block", displayPrint: "none" }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div style={{ textAlign: "left", fontSize: "16px" }}>
                  Site Address:
                </div>
              </Grid>
              <Grid item xs={9}>
                <h5>
                  <TextField
                    class="hide-on-print"
                    variant="outlined"
                    placeholder="Site Address"
                    fullWidth
                    size="small"
                    value={checklistDetails.siteAddress}
                    disabled={isPrinting}
                  />
                </h5>
              </Grid>
            </Grid>{" "}
          </Box>
          <Box
            sx={{
              display: "none",
              displayPrint: "block",
              textAlign: "left",
              fontSize: "16px",
            }}
          >
            Site Address: {checklistDetails.siteAddress}
          </Box>
        </TableCell>
      </TableRow>,
    ];
  };

  const renderBranches = () => {
    return (
      <FormGroup row style={{ marginTop: "-12px" }}>
        {filteredBranches.map((item, index) => (
          <FormControlLabel
            key={item}
            checked={
              filteredBranches.length === 1
                ? true
                : item === checklistDetails.branch
            }
            control={<Checkbox name={item} color="primary" />}
            onChange={(e) => {
              setChecklistDetails({
                ...checklistDetails,
                branch: e.target.name,
              });
            }}
            label={item}
            disabled={isPrinting}
          />
        ))}
      </FormGroup>
    );
  };

  useEffect(() => {
    if (isPrint === "print") {
      setIsPrinting(true);
      setTimeout(() => {
        handlePrint();
      }, 5000);
    }
  }, [isPrint]);

  const renderChecklistForm = () => {
    return (
      <div
        id="formToPrint"
        ref={elementRef}
        style={{ width: "100%", fontSize: "16px" }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <img
                  src={spartanLerrerHead}
                  alt="description"
                  style={{ width: "100%" }}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ textAlign: "center" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <b>Branch:&nbsp;&nbsp;&nbsp;</b>{" "}
                  </div>{" "}
                  <div>{renderBranches()}</div>
                </div>
              </TableCell>
            </TableRow>
            {renderStaticSection()}
            {renderSections(formConfig)}
          </TableBody>
        </Table>
        {sectionsVisible == formConfig.length && (
          <Table style={{ marginTop: "15px" }}>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  <h6
                    style={{
                      textAlign: "left",
                      padding: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Spartans Engineers
                  </h6>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box sx={{ display: "block", displayPrint: "none" }}>
                    Name:
                    <TextField
                      class="hide-on-print"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                      size="small"
                      value={checklistDetails.engineerName}
                      onChange={(e) => {
                        setChecklistDetails({
                          ...checklistDetails,
                          engineerName: e.target.value,
                        });
                      }}
                      disabled={isPrinting}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "none",
                      displayPrint: "block",
                      textAlign: "left",
                    }}
                  >
                    Name: {checklistDetails.engineerName}
                  </Box>
                  <Box sx={{ display: "block", displayPrint: "none" }}>
                    Designation:
                    <TextField
                      class="hide-on-print"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                      size="small"
                      value={checklistDetails.designation}
                      onChange={(e) => {
                        setChecklistDetails({
                          ...checklistDetails,
                          designation: e.target.value,
                        });
                      }}
                      disabled={isPrinting}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "none",
                      displayPrint: "block",
                      textAlign: "left",
                    }}
                  >
                    Designation: {checklistDetails.designation}
                  </Box>
                  <Box sx={{ display: "block", displayPrint: "none" }}>
                    Contact:
                    <TextField
                      class="hide-on-print"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                      size="small"
                      value={checklistDetails.contact}
                      onChange={(e) => {
                        setChecklistDetails({
                          ...checklistDetails,
                          contact: e.target.value,
                        });
                      }}
                      disabled={isPrinting}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "none",
                      displayPrint: "block",
                      textAlign: "left",
                    }}
                  >
                    Contact: {checklistDetails.contact}
                  </Box>
                  <Box>
                    <h6
                      style={{
                        textAlign: "left",
                        padding: "5px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      Signature:{" "}
                    </h6>
                    <SignatureCanvas
                      updateUrl={(url) => {
                        setChecklistDetails({
                          ...checklistDetails,
                          signatureURL: url,
                        });
                      }}
                      url={checklistDetails.signatureURL}
                    />{" "}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {sectionsVisible == formConfig.length && (
          <Table style={{ marginTop: "15px" }}>
            <TableBody>
              <TableRow>
                <TableCell rowSpan={3} width={300}>
                  <Box sx={{ display: "block", displayPrint: "none" }}>
                    Customer Name:
                    <TextField
                      class="hide-on-print"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                      size="small"
                      value={checklistDetails.customerNameSignature}
                      onChange={(e) => {
                        setChecklistDetails({
                          ...checklistDetails,
                          customerNameSignature: e.target.value,
                        });
                      }}
                      disabled={isPrinting}
                    />
                  </Box>
                  <h6
                    style={{
                      textAlign: "left",
                      padding: "5px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    Customer signature:
                  </h6>
                  <SignatureCanvas
                    updateUrl={(url) => {
                      setChecklistDetails({
                        ...checklistDetails,
                        customerSignatureURL: url,
                      });
                    }}
                    url={checklistDetails.customerSignatureURL}
                  />{" "}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <div>
          <Box
            sx={{
              display: "none",
              displayPrint: "block",
            }}
          >
            <img
              src={footerImage}
              alt="Image description"
              style={{ width: "100%" }}
            />
          </Box>
        </div>
      </div>
    );
  };

  return (
    <div style={{ paddingBottom: "20px" }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
        ></Grid>
      </Grid>
      <hr />
      <div>{renderChecklistForm()}</div>
      <hr />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button
            variant="contained"
            onClick={submitChecklist}
            disabled={isPrinting}
          >
            {sectionsVisible < formConfig.length ? "next / save" : "save"}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            onClick={() => navigate(`/checklist/edit/${checklistId}/${checklistTemplateId}`)}
          >
            print
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={() => {}}>
            cancel
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={loading}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100vh",
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="secondary" size={100} />
        </Box>
      </Modal>
    </div>
  );
}
