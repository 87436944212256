import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, useTheme, Divider } from "@mui/material";
import Chart from "./Chart";
import axios from "axios";

import { BiUserPlus } from "react-icons/bi";
import { FaCalculator } from "react-icons/fa";
import { SlBasket } from "react-icons/sl";
import { ImCross } from "react-icons/im";
import { BASE_URL } from "./../../global";
import { TbBuildingWarehouse } from "react-icons/tb";
import { BsArrowRightCircleFill } from "react-icons/bs";
import "./style.css";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const [countData, setCountData] = useState([]);
  const [checklistTempletData, setChecklistTempletData] = useState([]);
  const [checklistData1, setChecklistData] = useState([]);
  const theme = useTheme();

  const ColorArray = [
    {
      bgColor: "17a2b8",
      darkBg: "1c7685",
    },
    {
      bgColor: "28a745",
      darkBg: "238038",
    },
    {
      bgColor: "ffc107",
      darkBg: "cfa424",
    },
    {
      bgColor: "dc3545",
      darkBg: "ab2a36",
    },
    {
      bgColor: "6c757d",
      darkBg: "565f66",
    },
    {
      bgColor: "007bff",
      darkBg: "0f58a7",
    },
  ];

  const userId = localStorage.getItem("id");
  const userType = localStorage.getItem("type");


  const branchNames = [
    "AHMEDABAD",
    "BANGALORE",
    "CHENNAI",
    "DELHI",
    "HYDERABAD",
    "KOLKATTA",
    "MUMBAI",
    "PUNE",
  ];
  
  const userModulesString = localStorage.getItem("modules");
  const userModules = userModulesString ? userModulesString.split(",") : [];
  const uppercaseUserModules = userModules.map((module) =>
    module.toUpperCase()
  );
  const UserchecklistData = checklistData1.filter(
    (ele) => 
      Number(ele.checklistDetails.createdBy) === Number(userId) &&
      ele.checklistDetails.branch &&
      ele.checklistDetails.branch.toUpperCase() === uppercaseUserModules[0]
  );

  const userBrachData1 =
  Number(userType) === 1 ? checklistData1 : UserchecklistData;
  
  const branchCounts = branchNames.map((branchName) => {
    const filteredChecklist = userBrachData1.filter((check) => {
      const checklistBranch = check.checklistDetails.branch;
      return checklistBranch !== null && checklistBranch.toUpperCase() === branchName;
    });
  
    return {
      label: branchName,
      count: filteredChecklist.length,
    };
  });
   
  
  const checklistData =
    Number(userType) === 1 ? checklistData1 : UserchecklistData;


  const filterData = (checklistData, filterFunction) => {
    return checklistData.filter(filterFunction);
  };

  const getCountsByTemplate = (
    checklistTempletData,
    checklistData,
    filterFunction
  ) => {
    return checklistTempletData.map((template) => {
      const filteredData = filterData(checklistData, filterFunction(template));
      const countForTemplate = filteredData.filter(
        (e) => e.checklistDetails.name === template.name
      ).length;

      const filteredDataByName = filteredData.filter(
        (e) => e.checklistDetails.name === template.name
      );

      return {
        label: template.name,
        count: countForTemplate,
        data: filteredDataByName,
      };
    });
  };

  const todayCountsByTemplate = getCountsByTemplate(
    checklistTempletData,
    checklistData,
    (template) => (e) => {
      const lastModifiedDate = new Date(e.lastModified);
      const today = new Date();
      return (
        lastModifiedDate.getFullYear() === today.getFullYear() &&
        lastModifiedDate.getMonth() === today.getMonth() &&
        lastModifiedDate.getDate() === today.getDate()
      );
    }
  );

  const countofchecklist = getCountsByTemplate(
    checklistTempletData,
    checklistData,
    (template) => (e) => e.checklistDetails.name === template.name
  );

  const totalTodayCount = todayCountsByTemplate.reduce(
    (total, template) => total + template.count,
    0
  );

  const allTodayDataByTemplate = [
    { label: "Total Checklists", count: totalTodayCount },
    ...todayCountsByTemplate,
  ];

  const countarray1 =
    Number(userType) === 1
      ? [...countData, ...countofchecklist]
      : [...countofchecklist];

  const fetchData = (endpoint, label) => {
    axios
      .get(`${BASE_URL}${endpoint}`)
      .then((res) => {
        const newData = {
          label,
          count: res.data[0]?.no_of_rows || 0,
        };
        setCountData((prevData) => [...prevData, newData]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTemplateList = () => {
    axios
      .get(BASE_URL + "checklistTemplate?_where=(status,eq,1)")
      .then((res) => {
        let _templateList = [];
        res.data.forEach((rec) => {
          _templateList.push({
            id: rec.id,
            name: JSON.parse(rec?.templateDetails || {})?.name,
            config: JSON.parse(rec?.config || {}),
            lastModified: rec?.lastModified,
          });
        });
        setChecklistTempletData([..._templateList]);
      })
      .catch((err) => {
        console.log("checklist list data fetching error: ", err);
      });
  };

  const getCheckList = () => {
    axios
      .get(BASE_URL + "checklists")
      .then((res) => {
        let _templateList = [];
        res.data.forEach((rec) => {
          _templateList.push({
            id: rec.id,
            // name: JSON.parse(rec?.templateDetails || {})?.name,
            checklistDetails: JSON.parse(rec?.checklistDetails || {}),
            configValues: rec?.configValues,
            lastModified: rec?.lastModified,
          });
        });
        setChecklistData([..._templateList]);
      })
      .catch((err) => {
        console.log("checklist list data fetching error: ", err);
      });
  };

  useEffect(() => {
    fetchData("clients/count?_where=(status,eq,1)", "Clients");
    // fetchData("certificates/count?_where=(status,eq,1)", "Checklist Count");
    // fetchData("instruments/count?_where=(status,eq,1)", "Instrument Count");
    // fetchData("clients/count?_where=(status,eq,1)", "TodayClient Count");
    // fetchData("srfs/count?_where=(status,eq,1)", "Srf Count");
    // fetchData(
    //   "certificateStaticTables/count?_where=(status,eq,1)",
    //   "Today Certificate Count"
    // );
    // fetchData("srfs/count?_where=(status,eq,1)", "Srf Count");
    getTemplateList();
    getCheckList();
  }, []);

  return (
    <div>
      <Box p={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "16px",
            width: "content-width",
          }}
        >
          <Typography
            style={{
              color: "black",
              fontWeight: "bold",
            }}
            variant="h5"
            data-text="Overall Data:-"
          >
            Overall Activity:-
          </Typography>
        </div>
        <Box pb={2}>
          {/* Top Card */}
          <Grid container spacing={3} mt={2}>
            {countarray1.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    p: 4,
                    position: "relative",
                    borderRadius: "5px 5px 0px 0px",
                    backgroundColor: `#${ColorArray[index % 7]?.bgColor}`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                      marginLeft: "-120px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: card.label === "User" ? "black" : "white",
                        fontWeight:
                          card.label === "User" ? "bold" : "extrabold",
                      }}
                    >
                      {card.count}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: card.label === "User" ? "black" : "white",
                        fontWeight: "medium",
                        fontFamily: "press",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        paddingLeft: "120px",
                      }}
                    >
                      {card.label}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      py: 3,
                      color: card.label === "User" ? "gray.400" : "gray.300",
                      opacity: 0.5,
                      px: 2,
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.1)",
                        duration: 500,
                      },
                    }}
                  >
                    {card.label === "Clients" ? (
                      <BiUserPlus size={80} />
                    ) : (
                      <TbBuildingWarehouse size={80} />
                    )}
                  </Box>
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    gap: 2,
                    color: card.label === "User" ? "black" : "white",
                    backgroundColor: `#${ColorArray[index % 7]?.darkBg}`,
                    borderRadius: "0 0 4px 4px",
                    cursor: "pointer",
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Divider sx={{ borderTop: "2px solid" }} />
      <Box p={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "16px",
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "black",
              fontWeight: "bold",
            }}
            data-text="Engineer Report:-"
          >
            Daily Activity:-
          </Typography>
        </div>{" "}
        <Box pb={2}>
          {/* Top Card */}
          <Grid container spacing={3} mt={2}>
            {allTodayDataByTemplate.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    p: 4,
                    position: "relative",
                    borderRadius: "5px 5px 0px 0px",
                    backgroundColor: `#${ColorArray[index % 7]?.bgColor}`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                      marginLeft: "-120px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: card.label === "User" ? "black" : "white",
                        fontWeight:
                          card.label === "User" ? "bold" : "extrabold",
                      }}
                    >
                      {card.count}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: card.label === "User" ? "black" : "white",
                        fontWeight: "medium",
                        fontFamily: "press",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        paddingLeft: "120px",
                      }}
                    >
                      {card.label}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      py: 3,
                      color: card.label === "User" ? "gray.400" : "gray.300",
                      opacity: 0.5,
                      px: 2,
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.1)",
                        duration: 500,
                      },
                    }}
                  >
                    {card.label === "Clients" ? (
                      <BiUserPlus size={80} />
                    ) : (
                      <TbBuildingWarehouse size={80} />
                    )}
                  </Box>
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    gap: 2,
                    color: card.label === "User" ? "black" : "white",
                    backgroundColor: `#${ColorArray[index % 7]?.darkBg}`,
                    borderRadius: "0 0 4px 4px",
                    cursor: "pointer",
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Divider sx={{ borderTop: "2px solid" }} />
      <Box p={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "16px",
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "black",
              fontWeight: "bold",
            }}
            data-text="Job Workrport:-"
          >
            Work Report:-
          </Typography>
        </div>{" "}
        <Chart
          title={`Checklist Chart`}
          data={branchCounts}
          todayCountsByTemplate={todayCountsByTemplate}
        />
      </Box>
    </div>
  );
}
