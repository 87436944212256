import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import {
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 80 },

  {
    id: "name",
    label: "Template Name",
    align: "left",
    minWidth: 80,
  },
  {
    id: "createdDate",
    label: "Created Date",
    align: "left",
    minWidth: 80,
  },
  {
    id: "modifiedDate",
    label: "Modified Date",
    align: "left",
    minWidth: 80,
  },
];

export default function ChecklistLsit() {
  const [page, setPage] = React.useState(0);
  const [checklistTemplateList, setChecklistTemplateList] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitchecklistTemplateDelete = (id) => {
    console.log(id);

    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `checklistTemplate/${id}`, jsonData)
      .then((res) => {
        toast("template deleted succeessfully!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getChecklistTemplateList = () => {
    axios
      .get(BASE_URL + "checklistTemplate?_where=(status,eq,1)")
      .then((res) => {
        setChecklistTemplateList([...res.data]);
      })
      .catch((err) => {
        console.log("checklist template data fetching error: ", err);
      });
  };


  useEffect(() => {
    getChecklistTemplateList();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/checklistTemplate/edit/0"
              onClick={() => {
                {
                }
              }}
            >
              <b>ADD NEW template</b>
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
      <hr />

      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checklistTemplateList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit template" placement="top-start">
                        <Button
                          component={Link}
                          to={`/checklistTemplate/edit/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete template" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete tempalate?")
                            ) {
                              submitchecklistTemplateDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={checklistTemplateList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
