import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "companyName",
    label: "Company Name",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "contact",
  //   label: "Contact",
  //   align: "left",
  //   minWidth: 40,
  // },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "shippingAddress",
    label: "Location",
    align: "left",
    minWidth: 40,
  },
  {
    id: "branch",
    label: "Region",
    align: "left",
    minWidth: 40,
  },
  {
    id: "gstNumber",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "state",
    label: "State",
    align: "left",
    minWidth: 40,
  },
  {
    id: "email",
    label: "Email",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   align: "left",
  //   minWidth: 40,
  // },
];

function createData(
  id,
  companyName,
  // contact,
  address,
  shippingAddress,
  branch,
  gstNumber,
  state,
  email,
  status
) {
  return {
    id,
    companyName,
    // contact,
    address,
    shippingAddress,
    branch,
    gstNumber,
    state,
    email,
    status,
  };
}

export default function ClientsList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [clientList, setClientsList] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const userType = localStorage.getItem("type");
  const userBranch = localStorage.getItem("modules");

  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `clients/${id}`, jsonData)
      .then((res) => {
        toast("clients was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  function getClientList() {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Company Name": "companyName",
        "Address": "address",
        "Location": "shippingAddress",
        "Region": "branch",
      }[searchBy];
      whereConditions = `AND ${colName} LIKE '%${searchKey}%'`;
    }
    

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` lastModified between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (userType != "1")
    whereConditions +=
      whereConditions.length > 0
        ? `AND branch = '${userBranch}'`
        : `AND branch = '${userBranch}'`;


    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select *  from clients where status = 1  ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setClientsList([...res.data]);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    axios
      .get(BASE_URL + `clients/count`)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const search = () => {
    getClientList();
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  var rowDaata = [];
  for (let i = 0; i < clientList.length; i++) {
    rowDaata.push(
      createData(
        clientList[i].id,
        clientList[i].companyName,
        // clientList[i].contact,
        clientList[i].address,
        clientList[i].shippingAddress,
        clientList[i].branch,
        clientList[i].gstNumber,
        clientList[i].state,
        clientList[i].email,
        clientList[i].status
      )
    );
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/client/createClient"
          onClick={() => {
            {};
          }}
        >
          <b>ADD NEW CLIENT</b>
        </Button>
      </Toolbar>
      <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
      >
        <br />

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="combo-box-demo"
          options={[
            { key: "CompanyName", label: "Company Name" },
            { key: "address", label: "Address" },
            { key: "shippingAddress", label: "Location" },
            { key: "branch", label: "Region" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      <br />

      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
        width="100%"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  let value = row[column.id];
                  if (column.id == "id") {
                    value = page * rowsPerPage + (index + 1);
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="Edit Client" placement="top-start">
                      <Button
                        component={Link}
                        to={`/master/editClient/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                    {userType == 1 && (
                    <Tooltip title="Delete Client" placement="top-start">
                      <Button
                        onClick={(e) => {
                          if (window.confirm("Really want to delete client?")) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "#dc3545" }} />
                      </Button>
                    </Tooltip>
                    )}
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </TableContainer>
  );
}
