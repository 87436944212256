import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, DOMAIN } from "../../../global";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { Box, Tooltip } from "@mui/material";
import moment from "moment";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const columns = [
  { id: "id", label: "Sr. No", minWidth: 50 },

  {
    id: "date",
    label: "Date",
    align: "left",
    minWidth: 50,
  },
  {
    id: "branch",
    label: "Region ",
    align: "left",
    minWidth: 50,
  },
  {
    id: "customerName",
    label: "Customer Name ",
    align: "left",
    minWidth: 50,
  },
  {
    id: "siteAddress",
    label: "Site",
    align: "left",
    minWidth: 50,
  },
  {
    id: "name",
    label: "checklistName",
    align: "left",
    minWidth: 50,
  },
  {
    id: "engineerName",
    label: "Engineer Name",
    align: "left",
    minWidth: 100,
  },
];

const allRegions = [
  "AHMEDABAD",
  "BANGALORE",
  "CHENNAI",
  "DELHI",
  "HYDERABAD",
  "KOLKATTA",
  "MUMBAI",
  "PUNE",
];

const WorkReport = () => {
  const [checklistList, setChecklistList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [engineerName, setEngineerName] = useState(null);
  const [engineerList, setEngineerList] = useState([]);
  const [region, setRegion] = useState(null);
  //  console.log("checklistList",checklistList)
  const search = () => {
    const filteredData = checklistList.filter(item => {
      // Check date range
      const itemDate = item.date;
      const fromDate = from ?   moment(from).format("YYYY-MM-DD") : null;
      const toDate = to ? moment(to).format("YYYY-MM-DD")  : null;

      if ((fromDate && itemDate < fromDate) || (toDate && itemDate > toDate)) {
        return false;
      }

      // Check engineerName
      if (engineerName && item.engineerName !== engineerName) {
        return false;
      }

      // Check region
      if (region && item.branch !== region) {
        return false;
      }

      return true;
    });
    setDataList(filteredData )
    console.log('Filtered Data:', filteredData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getChecklistList = () => {
    axios
      .get(BASE_URL + "workReport?_where=(status,eq,1)")
      .then((res) => {
        const newData = res.data.map((e) => JSON.parse(e.checklistDetails)).reverse();
        setChecklistList(newData);
      })
      .catch((err) => {
        console.log("checklist list data fetching error: ", err);
      });
  };

  const getEngineerList = () => {
    axios
      .get(BASE_URL + "users?_where=(type,ne,1)")
      .then((res) => {
        let newData = [];
        let data = res.data;
        data.map((e) => newData.push(e.userName));
        setEngineerList(newData);
      })
      .catch((err) => {
        console.log("Engineer list data fetching error: ", err);
      });
  };

  useEffect(() => {
    getChecklistList();
    getEngineerList();
  }, []);

  return (
    <Box>
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          value={engineerName}
          sx={{ minWidth: 200 }}
          onChange={(event, newValue) => {
            setEngineerName(newValue);
          }}
          options={engineerList}
          renderInput={(params) => (
            <TextField {...params} label="Engineer Name" />
          )}
        />
        <br />
        <Autocomplete
          value={region}
          sx={{ minWidth: 200 }}
          onChange={(event, newValue) => {
            setRegion(newValue);
          }}
          options={allRegions}
          renderInput={(params) => <TextField {...params} label="Region" />}
        />

        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      <Box>
        <table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
        >
          <TableHead sx={{ alignItems: "center" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={checklistList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default WorkReport;
