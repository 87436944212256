import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { DialogTitle, useTheme, Grid } from "@mui/material";
import { Row } from "react-bootstrap";

const Chart = ({ title, data, todayCountsByTemplate }) => {
  const theme = useTheme();
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF3E3E",
    "#FF9A8B",
    "#D08EFF",
    "#81D8D0",
    "#C0C0C0",
    "#FFBF00",
  ];

  const chartData = data.map((item) => ({
    name: item.label,
    value: item.count,
  }));

  const chartData1 = todayCountsByTemplate.map((item) => ({
    name: item.label,
    value: item.count,
  }));

  const chartStyle = {
    border: "1px solid #ccc", 
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)", 
  };

  return (
    <Grid container spacing={2} marginTop="30px" direction="row">
      <Grid item xs={12} md={6} style={chartStyle}>
        <DialogTitle variant="h6" style={{ color: "black" }}>
          Chart of All Region 
        </DialogTitle>
        <ResponsiveContainer width="100%" height={350}>
          <PieChart > 
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill={theme.palette.primary.main}
              label={(entry) => entry.value === 0?"": `${entry.name} (${entry.value})`}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            
            <Legend verticalAlign="top" height={36} />
          </PieChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12} md={6}  style={chartStyle}>
        <DialogTitle variant="h6" style={{ color: "black" }}>
        Daily Checklist Chart
        </DialogTitle>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart> {/* Apply chart style */}
            <Pie
              data={chartData1}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill={theme.palette.primary.main}
              // label={(entry) => `${entry.name} (${entry.value})`}
            >
              {chartData1.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default Chart;
