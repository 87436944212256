import React, { useState } from "react";
import "./components/dashboard/style.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import { CircularProgress, Switch } from "@mui/material";

import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

import Main from "./components/home/main";
import { useEffect } from "react";
import {
  red,
  blue,
  purple,
  green,
  teal,
  orange,
  pink,
  yellow,
  brown,
  gray,
  grey,
} from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function App() {
  const [loader, setLoader] = useState(undefined);
  const [mode, setMode] = useState(false);
  const [themeColor, setThemecolor] = useState("blue");

  const getColorFromPalette = (colorName, shade) => {
    const colorPalette = {
      green,
      purple,
      blue,
      red,
      yellow,
      pink,
      orange,
      teal,
      brown,
      grey,
    };

    if (colorPalette[colorName]) {
      return colorPalette[colorName][shade];
    }

    return colorPalette.blue[shade];
  };

  const dynamicPrimaryColor = getColorFromPalette(themeColor, 900);
  const theme = createTheme({
    palette: {
      mode: mode ?  "dark":"light" ,
      primary: {
        main: `${dynamicPrimaryColor}`,
      },
      secondary: {
        main: green[500],
      },
    },
  });
  useEffect(() => {
    if (loader == false) {
      window.location.reload(true);
    }
  }, [loader]);

  console.log("themeColor", themeColor, dynamicPrimaryColor);
  return (
    <ThemeProvider theme={theme}>
      <div className={`app-container ${theme.containerClass} App`}>
        {loader && (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              background: "rgba(255,255,255, 0.85)",
              zIndex: "2",
            }}
          >
            <CircularProgress style={{ width: "70px", height: "70px" }} />
          </div>
        )}

        <Main
          setThemecolor={setThemecolor}
          setMode={setMode}
          mode={mode}
          setLoader={setLoader}
        />
      </div>
      

    </ThemeProvider>
  );
}
export default App;
