import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Paper,
  Typography,
  TextareaAutosize,
  IconButton,
  Divider,
  backdropClasses,
  TableCell,
  TableRow,
  TableBody,
  Table,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableHead,
  Modal,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import sampleTemplate from "./sampleTemplate.json";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const EditChecklistTemplate = () => {
  const { checklistTemplateId } = useParams();

  const [formConfig, setFormConfig] = useState(sampleTemplate);
  const [loading, setLoading] = React.useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: "",
  });

  const handleAddGridInput = () => {
    setFormConfig((prevConfig) => [
      ...prevConfig,
      {
        sectionName: "",
        columnCount: 3,
        sectionType: "gridInput",
        headersList: [[]],
        inputList: [],
      },
    ]);
  };

  // DATA HANDLERS
  const handleGridSectionAttributesChange = (sectionIndex, attribute) => {
    setFormConfig((prevConfig) => {
      let updatedConfig = [...prevConfig];
      Object.keys(attribute).forEach((key) => {
        updatedConfig[sectionIndex][key] = attribute[key];
      });

      return updatedConfig;
    });
  };

  const handleDeleteSection = (sectionIndex) => {
    setFormConfig((prevConfig) => {
      const updatedConfig = [...prevConfig];
      updatedConfig.splice(sectionIndex, 1);
      return updatedConfig;
    });
  };

  const handleAddInputTableRow = (sectionIndex) => {
    setFormConfig((prevConfig) => {
      const updatedConfig = [...prevConfig];

      const count = Number(updatedConfig[sectionIndex].columnCount) || 0;
      const cells = [];

      for (let i = 0; i < count; i++) {
        cells.push({
          label: "",
          type: "",
          attributes: [],
          rowSpan: 0,
          colSpan: 0,
        });
      }

      updatedConfig[sectionIndex].inputList.push({ cells: cells });
      return updatedConfig;
    });
  };

  const handleInputConfigTableCell = (
    sectionIndex,
    rowIndex,
    cellIndex,
    attribute
  ) => {
    setFormConfig((prevConfig) => {
      const updatedConfig = [...prevConfig];
      let key = Object.keys(attribute)[0];
      updatedConfig[sectionIndex].inputList[rowIndex].cells[cellIndex][key] =
        attribute[key];
      return updatedConfig;
    });
  };

  const handleDeleteInputTableRow = (sectionIndex, inputIndex) => {
    setFormConfig((prevConfig) => {
      const updatedConfig = [...prevConfig];
      updatedConfig[sectionIndex].inputList.splice(inputIndex, 1);
      return updatedConfig;
    });
  };

  const fetchChecklistTemplate = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(BASE_URL + `checklistTemplate/${id}`);
      const data = res.data[0];

      const response = await axios.get(data?.config);

      setFormConfig(response.data);

      let _templateDetails = {
        name: data?.name || "",
      };
      setTemplateDetails({ ...templateDetails, ..._templateDetails });
    } catch (e) {
      console.log("Failed to fetch  template: ", e);
    } finally {
      setLoading(false);
    }
  };

  // const fetchChecklistTemplate = (id) => {
  //   try {
  //     axios.get(BASE_URL + `checklistTemplate/${id}`).then((res) => {
  //       let data = res.data[0];
  //       data?.config && setFormConfig(JSON.parse(data?.config));
  //       let _templateDetails = {
  //         name: data?.name || "",
  //       };
  //       setTemplateDetails({ ...templateDetails, ..._templateDetails });
  //     });
  //   } catch (e) {
  //     console.log("Failed to fetch checklist template: ", e);
  //   }
  // };

  const submitChecklistTemplate = async () => {
    try {
      const formConfigJson = JSON.stringify(formConfig);
      const blob = new Blob([formConfigJson], {
        type: "application/json",
        referrerPolicy: "no-referrer",
      });

      const data = new FormData();
      data.append("fileToUpload", blob, "formConfig.json");

      const response = await axios.post(
        BASE_URL + "fileUpload/fileUpload.php",
        data
      );

      if (response.status === 200) {
        console.log("Response:", response.data);

        const templateData =
          checklistTemplateId < 1
            ? {
                name: templateDetails.name,
                templateDetails: JSON.stringify(templateDetails),
                config: response.data,
              }
            : {
                name: templateDetails.name,
                templateDetails: JSON.stringify(templateDetails),
                config: response.data,
              };

        const templateEndpoint =
          checklistTemplateId < 1
            ? BASE_URL + "checklistTemplate"
            : BASE_URL + `checklistTemplate/${checklistTemplateId}`;

        const templateResponse = await (checklistTemplateId < 1
          ? axios.post(templateEndpoint, templateData)
          : axios.patch(templateEndpoint, templateData));

        toast(
          templateResponse.status === 200
            ? "Template created/updated successfully!"
            : "Failed to create/update template."
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong!");
    }
  };

  // const submitChecklistTemplate = () => {
  //   if (checklistTemplateId < 1) {
  //     try {
  //       let data = {
  //         name: templateDetails.name,
  //         templateDetails: JSON.stringify(templateDetails),
  //         config: JSON.stringify(formConfig),
  //       };
  //       axios.post(BASE_URL + "checklistTemplate", data).then((res) => {
  //         toast("template created successfully !");
  //       });
  //     } catch (e) {
  //       console.log("Failed to submit checklist template: ", e);
  //     }
  //   } else {
  //     try {
  //       let data = {
  //         templateDetails: JSON.stringify(templateDetails),
  //         config: JSON.stringify(formConfig),
  //       };
  //       axios
  //         .patch(BASE_URL + `checklistTemplate/${checklistTemplateId}`, data)
  //         .then((res) => {
  //           toast("template updated successfully !");
  //         });
  //     } catch (e) {
  //       console.log("Failed to submit checklist template: ", e);
  //     }
  //   }
  // };

  useEffect(() => {
    if (checklistTemplateId > 0) {
      fetchChecklistTemplate(checklistTemplateId);
    }
  }, [checklistTemplateId]);

  // COMPONENTS TO RENDER
  const renderSectionHeaderInput = (section, sectionIndex) => {
    let count = section?.columnCount;
    let headerCells = [<TableCell></TableCell>];
    for (let i = 0; i < count; i++) {
      headerCells.push(
        <TableCell style={{ borderBottom: "2px solid black" }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Header"
                type="text"
                variant="outlined"
                size="small"
                value={section.headersList[0][i]?.split("$")[0]}
                onChange={(e) => {
                  handleGridSectionAttributesChange(sectionIndex, {
                    headersList: [
                      [
                        ...section.headersList[0].slice(0, i),
                        e.target.value,
                        ...section.headersList[0].slice(i + 1),
                      ],
                    ],
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              {/* checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      section.headersList[0][i]?.split("$")[1] === "true"
                    }
                    onChange={(e) => {
                      let value =
                        section.headersList[0][i]?.split("$")[0] +
                        "$" +
                        e.target.checked;
                      handleGridSectionAttributesChange(sectionIndex, {
                        headersList: [
                          [
                            ...section.headersList[0].slice(0, i),
                            value,
                            ...section.headersList[0].slice(i + 1),
                          ],
                        ],
                      });
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
        </TableCell>
      );
    }
    return headerCells;
  };

  const inputConfigTableCell = (cell, sectionIndex, rowIndex, cellIndex) => {
    return (
      <Grid container spacing={2}>
        {/* Input Field: Label */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Label"
            type="text"
            value={cell.label}
            onChange={(e) => {
              handleInputConfigTableCell(sectionIndex, rowIndex, cellIndex, {
                label: e.target.value,
              });
            }}
            variant="outlined"
            size="small"
          />
        </Grid>

        {/* Input Field: Type */}
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="field-type-label">Field Type</InputLabel>
            <Select
              labelId="field-type-label"
              value={cell.type}
              onChange={(e) => {
                handleInputConfigTableCell(sectionIndex, rowIndex, cellIndex, {
                  type: e.target.value,
                });
              }}
              label="Field Type"
              size="small"
            >
              <MenuItem value="text-field">Text Field</MenuItem>
              <MenuItem value="text-area">Text Area</MenuItem>
              <MenuItem value="dropdown">Dropdown</MenuItem>
              <MenuItem value="checkbox">Checkbox</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="none">None</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <TextField
            fullWidth
            label="Source Values"
            type="text"
            variant="outlined"
            size="small"
            value={cell.attributes}
            onChange={(e) => {
              handleInputConfigTableCell(sectionIndex, rowIndex, cellIndex, {
                attributes: e.target.value.split(","),
              });
            }}
          />
        </Grid>
        {cell.type == "text-field" && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Unit"
              type="text"
              variant="outlined"
              size="small"
              value={cell.unit}
              onChange={(e) => {
                handleInputConfigTableCell(sectionIndex, rowIndex, cellIndex, {
                  unit: e.target.value.split(","),
                });
              }}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Row Span"
            type="text"
            variant="outlined"
            size="small"
            onChange={(e) => {
              handleInputConfigTableCell(sectionIndex, rowIndex, cellIndex, {
                rowSpan: e.target.value.split(","),
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Col Span"
            type="text"
            variant="outlined"
            size="small"
            onChange={(e) => {
              handleInputConfigTableCell(sectionIndex, rowIndex, cellIndex, {
                colSpan: e.target.value.split(","),
              });
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const inputConfigTable = (section, sectionIndex) => {
    let inputList = section?.inputList;

    let inputableRows = inputList.map((input, rowIndex) => {
      let cells = input?.cells;
      cells = cells.map((cell, cellIndex) => {
        return (
          <TableCell>
            {inputConfigTableCell(cell, sectionIndex, rowIndex, cellIndex)}
          </TableCell>
        );
      });
      let deleteButton = (
        <TableCell>
          <IconButton
            onClick={() => handleDeleteInputTableRow(sectionIndex, rowIndex)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      );
      return (
        <TableRow>
          <TableCell>{rowIndex + 1}</TableCell>
          {cells}
          {deleteButton}
        </TableRow>
      );
    });

    return (
      <div>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <IconButton onClick={() => handleAddInputTableRow(sectionIndex)}>
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              {renderSectionHeaderInput(section, sectionIndex)}
            </TableRow>
          </TableHead>
          <TableBody>{inputableRows}</TableBody>
        </Table>
      </div>
    );
  };
  const renderGridInputSection = (section, sectionIndex) => {
    return (
      <Grid item xs={12} key={sectionIndex}>
        <Paper style={{ padding: "1rem" }}>
          <Grid container alignItems="center" spacing={2}>
            {/* Grid input section */}
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <Typography variant="h6">Section #{sectionIndex + 1}</Typography>
            </Grid>

            {/* Delete Button */}
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton onClick={() => handleDeleteSection(sectionIndex)}>
                <DeleteIcon />
              </IconButton>
            </Grid>

            {/* Section Name and Columns Input Fields */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Section Name"
                variant="outlined"
                size="small"
                value={section.sectionName}
                onChange={(event) =>
                  handleGridSectionAttributesChange(sectionIndex, {
                    sectionName: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="field-type-label">Section Type</InputLabel>
                <Select
                  labelId="field-type-label"
                  value={section.sectionType}
                  onChange={(e) => {
                    handleGridSectionAttributesChange(sectionIndex, {
                      sectionType: e.target.value,
                    });
                  }}
                  label="Section Type"
                  size="small"
                >
                  <MenuItem value="gridInput">Grid input</MenuItem>
                  <MenuItem value="imageGroup">Image group</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Column Count"
                variant="outlined"
                size="small"
                value={section.columnCount}
                onChange={(event) =>
                  handleGridSectionAttributesChange(sectionIndex, {
                    columnCount: event.target.value,
                  })
                }
              />
            </Grid>

            {/* TODO: remove it if not required at all
            // Header List Input Field
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Header List"
                variant="outlined"
                size="small"
                value={section.headersList}
                onChange={(event) =>
                  handleGridSectionAttributesChange(sectionIndex, {
                    headersList: [event.target.value.split(",")],
                  })
                }
              />
            </Grid> */}
          </Grid>
          <hr />
          {inputConfigTable(section, sectionIndex)}
        </Paper>
      </Grid>
    );
  };

  const renderSection = (section, sectionIndex) => {
    let sectionOutput = null;
    if (section.sectionType === "gridInput") {
      sectionOutput = renderGridInputSection(section, sectionIndex);
    } else if (section.sectionType === "imageGroup") {
      sectionOutput = renderGridInputSection(section, sectionIndex);
    }
    return sectionOutput;
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {checklistTemplateId == 0 ? "Create " : "Edit"} Checklist Template
          </Typography>
        </Grid>
        <Grid item xs={4} style={{}}>
          <TextField
            fullWidth
            label="Template Name"
            type="text"
            value={templateDetails.name}
            onChange={(e) => {
              setTemplateDetails({ ...templateDetails, name: e.target.value });
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button variant="outlined" onClick={handleAddGridInput}>
            Add Section
          </Button>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2}>
        {formConfig.map((section, sectionIndex) =>
          renderSection(section, sectionIndex)
        )}
      </Grid>
      <hr />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button variant="contained" onClick={submitChecklistTemplate}>
            SAVE
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={handleAddGridInput}>
            CANCEL
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={loading}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100vh",
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="secondary" size={200} />
        </Box>
      </Modal>
    </div>
  );
};

export default EditChecklistTemplate;
