import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import SummarizeIcon from "@mui/icons-material/Summarize";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Settings from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import StarBorder from "@mui/icons-material/StarBorder";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import BuildIcon from "@mui/icons-material/Build";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import CategoryIcon from "@mui/icons-material/Category";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TableViewIcon from "@mui/icons-material/TableView";
import PersonIcon from "@mui/icons-material/TableView";

import ArticleIcon from "@mui/icons-material/Article";
import TableChartIcon from "@mui/icons-material/TableChart";
// ----------------------------------------------------
// import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChecklistIcon from '@mui/icons-material/Checklist';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { Box, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    "& .MuiToolbar-root": {
      color: "grey",
      "&:hover": {
        color: "black",
      },
    },
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "#343a40",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#343a40",
  },
  listItem: {
    color: "#c2c7d0",
    "&:hover": {
      backgroundColor: "grey",
      color: "white",
    },
  },
  selectedListItem: {
    backgroundColor: "white",
    color: "grey",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [masterNested, setMasterNested] = React.useState(false);
  const [srfNested, setSrfNested] = React.useState(false);
  const [paymentNested, setPaymentNested] = React.useState(false);
  const [intermediateProcedure, setIntermediateProcedure] =
    React.useState(false);
  const classes = useStyles();
  const userName = localStorage.getItem("userName");
  const userType = localStorage.getItem("type");
  const modules = localStorage.getItem("modules")?.split(",");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    props.setIsLoggedIn(false);
    window.localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };

  const hasAccess = (key) => {
    if (userType == 1) return true;
    else if (userType == 2) {
      if (modules?.includes(key)) return true;
      else return false;
    }
    // ToDo: add logic for client login
    else {
      if ("".includes(key)) return true;
      else return false;
    }
  };

  const onIdle = () => {
    logout();
  };

  const onActive = () => {};

  const onAction = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 15 * 60 * 1000,
    throttle: 250,
  });

  // -----------------------------------------------

  const [open1, setOpen1] = React.useState(false);
  const anchorRef = React.useRef(null);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open} className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            align="left"
          ></Typography>
          <Button color="inherit" onClick={logout}>
            <AccountCircleIcon />
            &nbsp;
            {userName} &nbsp;
            <span style={{ textTransform: "lowercase" }}>
              ({["admin", "Engineer", "client"][userType - 1]})
            </span>
            &nbsp;&nbsp; |&nbsp;&nbsp; Logout
          </Button>
        </Toolbar>
      </AppBar>
 
      <Drawer variant="permanent" open={open}   >
        <DrawerHeader className={classes.drawer}>
          <Typography sx={{color:"white", fontWeight:"bold"}} variant="h6" gutterBottom component="div">
            Spartan Engg
          </Typography>
          <IconButton sx={{color:"white", fontWeight:"bold"}} onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Box sx={{
          height:"90vh",
          overflow: "hidden !important"
        }}  className={classes.drawer}>
        <List
        className={classes.drawer}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton  className={classes.listItem}  component={Link} to="/dashboard" onClick={() => {}}>
            <ListItemIcon className={classes.listItem} >
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary={<span style={{ fontWeight: "bold" }}>Dashboard</span>}
            />
          </ListItemButton>

          {hasAccess("1") && (
            <ListItemButton
            className={classes.listItem} 
              component={Link}
              to="/checklistTemplateList"
              onClick={() => {}}
            >
              <ListItemIcon className={classes.listItem}>
                <ChecklistRtlIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span style={{ fontWeight: "bold" }}>Checklist Template</span>
                }
              />
            </ListItemButton>
          )}

          <ListItemButton
          className={classes.listItem}  component={Link} to="/checklist" onClick={() => {}}>
            <ListItemIcon className={classes.listItem}>
              <ChecklistIcon />
            </ListItemIcon>
            <ListItemText
              primary={<span style={{ fontWeight: "bold" }}>Checklists</span>}
            />
          </ListItemButton>

          {/* {hasAccess("1") && ( */}
            <ListItemButton
            className={classes.listItem} 
              component={Link}
              to="/master/client"
              onClick={() => {}}
            >
              <ListItemIcon className={classes.listItem}>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Clients</span>}
              />
            </ListItemButton>
       {/* )} */}

          {hasAccess("1") && (
            <ListItemButton
            className={classes.listItem} 
              component={Link}
              to="/master/users/userList"
              onClick={() => {}}
            >
              <ListItemIcon className={classes.listItem}>
                <GroupAddIcon />
              </ListItemIcon>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Users</span>}
              />
            </ListItemButton>
         )}
           {hasAccess("1") && (
            <ListItemButton
            className={classes.listItem} 
              component={Link}
              to="/checklist/WorkReport"
              onClick={() => {}}
            >
              <ListItemIcon className={classes.listItem}>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Work Report</span>}
              />
            </ListItemButton>
         )}
        </List>
        </Box>
      </Drawer>
   
      
    </>
  );
}
