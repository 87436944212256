import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';

import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import QRCode from "react-qr-code";
import QRTemplateImage from "../image/qrTemplate.png";
import SearchIcon from "@mui/icons-material/Search";
import Image from "mui-image";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "name",
    label: "Checklist Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "customerName",
    label: "Customer Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "siteAddress",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "model",
    label: "Model Number",
    align: "left",
    minWidth: 80,
  },
  {
    id: "machineSerialNumber",
    label: "Machine Serial Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "date",
    label: "Date",
    align: "left",
    minWidth: 100,
  },
];

const qrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ChecklistLsit() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [checklistList, setChecklistList] = React.useState([]);
  const [templateList, setTemplateList] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(0);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const handleClose = () => setQrModalOpen(false);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [modelNumber, setModelNumber] = React.useState("");
  const [machineSerialNumber, setmachineSerialNumber] = React.useState("");
  const userType = localStorage.getItem("type");
  const _clientId = localStorage.getItem("id");

  const search = () => {
    getChecklistList();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {

    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `checklists/${id}`, jsonData)
      .then((res) => {
        toast("checklist deleted successfully!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getChecklistList = () => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Customer Name": "customerName",
        Address: "siteAddress",
        "Model Number": "model",
        "Machine Serial Number": "machineSerialNumber",
      }[searchBy];
      if(colName=="model"){
        whereConditions = ` AND LOWER(JSON_UNQUOTE(JSON_EXTRACT(configValues, '$[0].inputList[1].cells[1].value'))) LIKE LOWER('%${searchKey}%')`;

      }
      else if(colName=="machineSerialNumber"){
        whereConditions = ` AND LOWER(JSON_UNQUOTE(JSON_EXTRACT(configValues, '$[0].inputList[2].cells[1].value'))) LIKE LOWER('%${searchKey}%')`;
}
      else{
        whereConditions = `AND LOWER(JSON_UNQUOTE(JSON_EXTRACT(checklistDetails, '$.${colName}'))) LIKE LOWER('%${searchKey}%')`;

      }
    }

    if (userType != "1")
      whereConditions +=
        whereConditions.length > 0
          ? `AND JSON_UNQUOTE(JSON_EXTRACT(checklistDetails, '$.createdBy')) = '${_clientId}'`
          : `AND JSON_UNQUOTE(JSON_EXTRACT(checklistDetails, '$.createdBy')) = '${_clientId}'`;

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `SELECT * FROM checklists WHERE status = 1 ${whereConditions} ORDER BY id DESC ${pagination_settings}`,
    };

    axios.post(BASE_URL + `dynamic`, data)
  .then(async (res) => {
    const updatedChecklistList = await Promise.all(res.data.map(async (checklist) => {
      try {
        const configResponse = await axios.get(checklist.configValues);
        const configValuesString = JSON.stringify(configResponse.data);
        return { ...checklist, configValues: configValuesString };
      } catch (error) {
        console.error("Error fetching config values:", error);
        return checklist; // Return the original checklist object if fetching fails
      }
    }));
    setChecklistList(updatedChecklistList);
  })
  .catch((err) => {
    console.log("Checklist list data fetching error: ", err);
  });


    // axios
    //   .post(BASE_URL + `dynamic`, data)
    //   .then((res) => {

    //     setChecklistList([...res.data]);
    //   })
    //   .catch((err) => {
    //     console.log("checklist list data fetching error: ", err);
    //   });
  };

  const getTemplateList = () => {
    axios
      .get(BASE_URL + "checklistTemplate?_where=(status,eq,1)")
      .then((res) => {
        let _templateList = [];
        res.data.forEach((rec) => {
          _templateList.push({
            id: rec.id,
            name: JSON.parse(rec?.templateDetails || {})?.name,
          });
        });
        setTemplateList([..._templateList]);
      })
      .catch((err) => {
        console.log("checklist list data fetching error: ", err);
      });
  };

  const handlePrint = () => {
    const printContent = document.getElementById("componentToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = printContent.innerHTML;

      setTimeout(() => {
        window.print();
        document.body.innerHTML = originalContents;
      }, 100);
    }
  };

  const handleAddNewClick = (url) => {
    if (selectedTemplate > 0) {
      window.location.href = url;
    } else {
      toast.error('Please select a template first !', {
        position: 'top-center',
        autoClose: 3000, 
      });
    }
  };

  useEffect(() => {
    getTemplateList();
  }, []);
  useEffect(() => {
    getChecklistList();
  }, [rowsPerPage]);

  const renderQrModal = () => {
    return (
      <Modal
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={qrModalStyle}>
            <div id="componentToPrint">
              <img src={QRTemplateImage} height={"550px"} />
              <Typography
                  style={{
                    position: "absolute",
                    top: "25%",
                    left: "0%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                    width: "100%",
                    fontWeight: "600",
                    fontSize: "12.5px",
                    color:"black",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  }}
                >
                  Model Number : {modelNumber}<br />
                  Machine Serial Number : {machineSerialNumber}
                </Typography>
              <Paper
                elevation={0}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <QRCode
                  value={`${DOMAIN}/#/checklist/edit/${selectedRowId}/0/print`}
                  align="center"
                  size={220}
                />
              </Paper>
            </div>
            <Button
              variant="contained"
              onClick={() => {
                handlePrint();
              }}
            >
              print
            </Button>
          </Box>
        </div>
      </Modal>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="field-type-label">Template List</InputLabel>
              <Select
                labelId="field-type-label"
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);
                }}
                label="Field Type"
                size="small"
              >
                {templateList.map((rec) => {
                  return <MenuItem value={rec.id}>{rec.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleAddNewClick(`/#/mobileChecklist/edit/0/${selectedTemplate}`)}
            >
              <AppShortcutIcon/> &nbsp;
              <b>ADD NEW </b>
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleAddNewClick(`/#/checklist/edit/0/${selectedTemplate}`)}
            >
              <b>ADD NEW checklist</b>
            </Button>
          </Grid>

        </Grid>
      </Toolbar>
      <hr />

      <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div
          className="mb-2"
          style={{ display: "flex", float: "left", justifyContent: "flex-end" , marginRight: "20px",}}
        >
          <br />

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="combo-box-demo"
            options={[
              { key: "customerName", label: "Customer Name" },
              { key: "siteAddress", label: "Address" },
              { key: "model", label: "Model Number" },
              { key: "machineSerialNumber", label: "Machine Serial Number" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </div>
      </div>

      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checklistList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              // let parseData= JSON.parse(row?.configValues)
              // let ID=row?.id
              // console.log({ID,parseData})
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value;

                    if (column.id === "model") {
                      value =
                        JSON.parse(row?.configValues)?.[0]?.inputList?.[1]
                          ?.cells[1]?.value || null;
                    } else if (column.id === "machineSerialNumber") {
                      value =
                        JSON.parse(row?.configValues)?.[0]?.inputList?.[2]
                          ?.cells[1]?.value || null;
                    } else if (column.id === "id") {
                      value = index + 1;
                    } else {
                      value = JSON.parse(row?.checklistDetails)?.[column.id];
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="View checklist in Mobile" placement="top-start">
                        <Button
                          component={Link}
                          to={`/mobileChecklist/edit/${row.id}/0`}
                        >
                          <MobileScreenShareIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="View checklist" placement="top-start">
                        <Button
                          component={Link}
                          to={`/checklist/edit/${row.id}/0`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="checklist QR code" placement="top-start">
                        <Button
                          component={Link}
                          // to={`/checklist/edit/${row.id}/0`}
                          onClick={() => {
                            setSelectedRowId(row.id);
                            setQrModalOpen(true);
                            setModelNumber(JSON.parse(row?.configValues)?.[0]?.inputList?.[1]
                            ?.cells[1]?.value || null)
                            setmachineSerialNumber(JSON.parse(row?.configValues)?.[0]?.inputList?.[2]
                            ?.cells[1]?.value || null)
                          }}
                        >
                          <QrCode2Icon />
                        </Button>
                      </Tooltip>
                      {userType == 1 && (
                        <Tooltip title="Delete Checklist" placement="top-start">
                          <Button
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete checklist?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100,{ label: "All", value: -1 }]}
        component="div"
        count={checklistList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
      {renderQrModal()}
    </TableContainer>
  );
}
