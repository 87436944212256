import React, { useState } from "react";
import Box from "@mui/material/Box";
import { HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./../common/navbar";
import ClientNavbar from "./../common/clientNavbar";
import Dashboard from "./../dashboard/Dashboard";
import Login from "./../login/Login";
import ClientLogin from "../login/clientLogin";
import { ToastContainer } from "react-toastify";
import EditChecklist from "../checklists/editChecklist";
import ChecklistList from "../checklists/checklistList";
import EditChecklistTemplate from "../checklistTemplate/editChecklistTemplate";
import ChecklistTemplateLsit from "../checklistTemplate/checklistTemplateList";
import ClientsList from "../master/clients/clientsList";
import UpdateClient from "../master/clients/editClients";
import CreateClient from "../master/clients/createClient";
import AddUserMaster from "../master/userMaster/addUser";
import EditUserMaster from "../master/userMaster/editUserMaster";
import UserList from "../master/userMaster/userList";
import { Switch } from "@mui/material";
import WorkReport from "../checklists/WorkReport/workReport";
import { PrintContextProvider } from "../checklists/PrintContext";
import MobileEditChecklist from "../checklists/mobileEditChecklist";

export default function Main(props) {
  const [title, setTitle] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );
  let PropsMode = props.mode;
  let PropsSetMode = props.setMode;
  let PropsSetThemecolor = props.setThemecolor;
  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <HashRouter>
        {localStorage.getItem("isLoggedIn") &&
          localStorage.getItem("type") != 3 && (
            <Navbar
              setThemecolor={PropsSetThemecolor}
              mode={PropsMode}
              setMode={PropsSetMode}
              title={title}
              setIsLoggedIn={setIsLoggedIn}
            />
          )}
        {localStorage.getItem("isLoggedIn") &&
          localStorage.getItem("type") == 3 && (
            <ClientNavbar title={title} setIsLoggedIn={setIsLoggedIn} />
          )}

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, pt: 9, width: "100%", height: "100%" }}
        >
          <Routes>
            {!localStorage.getItem("isLoggedIn") && (
              <Route
                index
                element={
                  <Login
                    setIsLoggedIn={setIsLoggedIn}
                    setLoader={props.setLoader}
                  />
                }
              />
            )}
            {localStorage.getItem("isLoggedIn") && (
              <Route index element={<Dashboard />} />
            )}
            <Route
              path="/clientLogin"
              element={<ClientLogin setLoader={props.setLoader} />}
            />
            <Route path="/dashboard" element={<Dashboard />}></Route>

            <Route path="/checklist" element={<ChecklistList />}></Route>
            <Route
              path="/checklist/edit/:checklistId/:checklistTemplateId/:isPrint?"
              element={
                <PrintContextProvider>
                  <EditChecklist />
                </PrintContextProvider>
              }
            ></Route>
                        <Route
              path="/mobileChecklist/edit/:checklistId/:checklistTemplateId/:isPrint?"
              element={
                <PrintContextProvider>
                  <MobileEditChecklist />
                </PrintContextProvider>
              }
            ></Route>
            <Route
              path="/checklistTemplateList"
              element={<ChecklistTemplateLsit />}
            ></Route>
            <Route
              path="/checklist/WorkReport"
              element={<WorkReport />}
            ></Route>
            <Route
              path="/checklistTemplate/edit/:checklistTemplateId"
              element={<EditChecklistTemplate />}
            ></Route>
            <Route
              path="/master/client"
              element={<ClientsList />}
              onEnter={() => setTitle("Client")}
            ></Route>
            <Route
              path="/master/editClient/:id"
              element={<UpdateClient setLoader={props.setLoader} />}
              onEnter={() => setTitle("Client")}
            ></Route>
            <Route
              path="/master/client/createClient"
              element={<CreateClient setLoader={props.setLoader} />}
              onEnter={() => setTitle("Discipline / Create discipline")}
            ></Route>
            <Route
              path="/master/users/addUser"
              element={<AddUserMaster setLoader={props.setLoader} />}
              onEnter={() => setTitle("Master/ add new user")}
            ></Route>
            <Route
              path="/master/users/editUser/:id"
              element={<EditUserMaster setLoader={props.setLoader} />}
              onEnter={() => setTitle("Master/ edit user")}
            ></Route>
            <Route
              path="/master/users/userList"
              element={<UserList setLoader={props.setLoader} />}
              onEnter={() => setTitle("Master/ users list")}
            ></Route>
          </Routes>
        </Box>
      </HashRouter>
      <ToastContainer />
    </Box>
  );
}
