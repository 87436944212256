import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import React, { useContext } from "react";
import InlineCheckbox from "../components/InlineCheckbox";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import CustomDropdown from "../components/CustomDropdown";
import { PrintContext } from "../PrintContext";

export default function MobileGridInput(props) {
  let {
    config,
    sectionIndex,
    handleInputConfigTableCellValue,
    handleFileUpload,
  } = props;
  const { isPrinting } = useContext(PrintContext);

  const [headerList, setHeaderList] = React.useState([]);
  const [hiddenCols, setHiddenCols] = React.useState([]);
  const [rowList, setRowList] = React.useState([]);
  const [value, setValue] = React.useState({});
  const [fileName, setFileName] = React.useState({ name: null });

  const renderTableHeader = () => {
    let _headerList = [];
    let _hiddenCols = [];
    config.headersList?.forEach((headerRow) => {
      let header = [];
      headerRow.forEach((headerCell, index) => {
        let value = headerCell?.split("$")[0];
        let className = "p-2";
        if (headerCell?.split("$")?.[1] === "true") {
          _hiddenCols.push(index);
          className = className + " hide-on-print";
        }
        // header.push(<TableCell class={className}>123{value}</TableCell>);
      });
   
      _headerList.push(<TableRow>{header}</TableRow>);
    });
    setHeaderList([..._headerList]);
    setHiddenCols([..._hiddenCols]);
    return _hiddenCols;
  };

  const renderTableCell = (cell, sectionIndex, rowIndex, cellIndex) => {
    let renderOutput = [];
    let displayStyle = "grid";//flex

    if (cell?.type === "text-field") {
      renderOutput.push(
        <div>
          <Box sx={{ display: "block", displayPrint: "none" ,  alignItems: "flex-start"}}>
            <TextField
              class="hide-on-print"
              variant="outlined"
              placeholder=""
              // fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{cell?.unit}</InputAdornment>
                ),
              }}
              value={cell?.value}
              onChange={(e) => {
                handleInputConfigTableCellValue(
                  sectionIndex,
                  rowIndex,
                  cellIndex,
                  e.target.value
                );
                setValue({
                  ...value,
                  ["" + sectionIndex + rowIndex + cellIndex]: e.target.value,
                });
              }}
              disabled={isPrinting}
            />
          </Box>
          <Box
            sx={{
              display: "none",
              displayPrint: "block",
              textAlign: "left",
            }}
          >
            <div>
              {cell?.value} {cell?.value ? cell?.unit : ""}
            </div>
          </Box>
        </div>
      );
    }
    if (cell?.type === "checkbox") {
      let checkboxItems = [];
      let value = cell?.value?.split(",");
      cell?.attributes?.forEach((attribute, index) => {
        checkboxItems.push({
          name: attribute,
          label: attribute,
          value: value?.[index],
        });
      });
      renderOutput.push(
        <InlineCheckbox
          items={checkboxItems}
          sectionIndex={sectionIndex}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          handleInputConfigTableCellValue={handleInputConfigTableCellValue}
        />
      );
    }
    if (cell?.type === "text-area") {
      displayStyle = "block";
      renderOutput.push(
        <TextField
          fullWidth
          id="outlined-multiline-static"
          // label="Text Area"
          multiline
          rows={6} 
          variant="outlined"
          value={cell?.value}
          onChange={(e) => {
            handleInputConfigTableCellValue(
              sectionIndex,
              rowIndex,
              cellIndex,
              e.target.value
            );
            setValue({
              ...value,
              ["" + sectionIndex + rowIndex + cellIndex]: e.target.value,
            });
          }}
          disabled={isPrinting}
        />
      );
    }
    if (cell?.type === "image") {
      renderOutput.push(
        <Box style={{  justifyContent: "flex-start"}}>
          <input
            accept="image/*"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              handleFileUpload(
                sectionIndex,
                rowIndex,
                cellIndex,
                e.target.files[0]
              );
              setFileName({ ...fileName, name: e.target.files[0].name });
            }}
            id={"image-upload" + sectionIndex + rowIndex + cellIndex}
            disabled={isPrinting}
          />
          <label htmlFor={"image-upload" + sectionIndex + rowIndex + cellIndex}>
            <Button
              color={cell?.fileName ? "success" : "primary"}
              variant="outlined"
              size="small"
              component="span"
              disabled={isPrinting}
            >
              <CloudUploadIcon/>  &nbsp;
              Select Image
            </Button>
          </label>
          {/* {<div>File: {cell?.fileName || "No file found"}</div>} */}
        </Box>
      );
    }
    if (cell?.type === "dropdown") {
      renderOutput.push(
        <CustomDropdown
          cell={cell}
          sectionIndex={sectionIndex}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          handleInputConfigTableCellValue={handleInputConfigTableCellValue}
        />
      );
    }
    if (cell?.type === "date") {
      renderOutput.push(<input type="date" id="datePicker" />);
    }

    if (cell?.label) {
      if (renderOutput.length > 0) {
        renderOutput = (
          <div style={{ display: displayStyle, pageBreakInside: "avoide" }}>
            <div>
              <div
                style={{
                  float: "left",
                  marginLeft: "10px",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <label>{cell.label}</label>
              </div>
            </div>
            <div>
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {renderOutput}
              </div>
            </div>
          </div>
        );
      } else {
        renderOutput = [
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  float: "left",
                  marginLeft: "10px",
                  marginRight: "5px",

                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <label>{cell.label}</label>
              </div>
            </Grid>
          </Grid>,
        ];
      }
    } else {
      renderOutput = (
        <div
          style={{
            marginLeft: "10px",
            marginTop: "5px",
            marginRight: "5px",
            marginBottom: "5px",
          }}
        >
          {renderOutput}
        </div>
      );
    }

    return renderOutput;
  };

  const renderTableRows = (_hiddenCols) => {
    let _rowList = [];
    config.inputList.forEach((input, rowIndex) => {
      let cells = [];
      let colSpan = 0;
      input.cells.forEach((cell, cellIndex) => {
        let className = "";
        if (_hiddenCols.includes(cellIndex)) {
          className = "hide-on-print";
        }

        if (colSpan === 0 && cell?.colSpan !== 0) colSpan = cell?.colSpan;
        else if (colSpan !== 0) {
          colSpan--;
          return;
        }

        // cell styling by type
        let style = {};
        if (cell.type !== "") {
          // style = { minWidth: "100%" };
        }

        cells.push(
          <Box
            style={style}
            display="flex"
            flexDirection="column" 
            class={className}
            
          >
            {renderTableCell(cell, sectionIndex, rowIndex, cellIndex)}
          </Box>
        );
      });

      _rowList.push(
        <Box
          sx={{ border: "1px solid black", display: "flex",padding: "5px", marginBottom: "20px", flexDirection:"column",  alignItems: "flex-start" }}
        >
          {cells}
        </Box>
      );
    });
    setRowList([..._rowList]);
  };

  React.useEffect(() => {
    let _hiddenCols = renderTableHeader();
    renderTableRows(_hiddenCols);
  }, [config, value]);

  return (
    <Table id={props?.id + "table1"} style={{ overflowX: "auto" }}>
      <TableHead>{headerList}</TableHead>
      <TableBody>{rowList}</TableBody>
    </Table>
  );
}
