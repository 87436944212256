import React, { createContext, useState } from "react";

export const PrintContext = createContext();

export function PrintContextProvider({ children }) {
  const [isPrinting, setIsPrinting] = useState(false);

  return (
    <PrintContext.Provider value={{ isPrinting, setIsPrinting }}>
      {children}
    </PrintContext.Provider>
  );
}
